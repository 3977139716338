var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"page-Biadjo-view"}},[_c('vs-alert',{attrs:{"color":"danger","title":"Biadjo Not Found","active":_vm.Biadjo_not_found},on:{"update:active":function($event){_vm.Biadjo_not_found=$event}}},[_c('span',[_vm._v("Biadjo record with id: "+_vm._s(_vm.$route.params.Id)+" not found.")]),_c('span',[_c('span',[_vm._v("Check")]),_c('router-link',{staticClass:"text-inherit underline",attrs:{"to":{ name: 'BiadjoDetails' }}},[_vm._v("All Biadjos")])],1)]),_c('div',{staticClass:"w-full",attrs:{"id":"Scroll"}},[_c('vx-card',[(_vm.ClompanyModel != undefined || _vm.ClompanyModel != null)?_c('div',{staticClass:"data-list-container",attrs:{"id":"data-list-list-view"}},[_c('vs-table',{ref:"table",attrs:{"pagination":"","search":"","data":_vm.ClompanyModel},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return [_c('tbody',_vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',[_c('p',{staticClass:"id font-medium truncate"},[_vm._v(" "+_vm._s(tr.firstName)+" "+_vm._s(tr.lastName)+" ")])]),_c('vs-td',[_c('p',{staticClass:"PhoneNumber"},[_vm._v(" "+_vm._s(tr.PhoneNumber)+" ")])]),_c('vs-td',[_c('p',{staticClass:"Email"},[_vm._v(_vm._s(tr.Email))])]),_c('vs-td',[_c('vs-chip',{staticClass:"Status",attrs:{"color":_vm.getOrderStatusColor(tr.AccountStatus)}},[_vm._v(_vm._s(_vm.$t(tr.AccountStatus)))])],1),_c('vs-td',[_c('vs-chip',{staticClass:"AccountType"},[_vm._v(_vm._s(_vm.$t(tr.AccountType)))])],1),_c('vs-td',{staticClass:"whitespace-no-wrap"},[_c('router-link',{attrs:{"to":{
                      name: 'ClientProfile',
                      params: { Id: tr.Id }
                    }}},[_c('vs-button',{staticClass:"w-full",attrs:{"size":"small"}},[_vm._v(_vm._s(_vm.$t("ClientProfile")))])],1)],1),_c('vs-td',{staticClass:"whitespace-no-wrap"},[_c('router-link',{attrs:{"to":{
                      name: 'RequestTrip',
                      params: { CID: tr.Id }
                    }}},[_c('vs-button',{staticClass:"w-full",attrs:{"size":"small"}},[_vm._v(_vm._s(_vm.$t("RequestBiadjo")))])],1)],1)],1)}),1)]}}],null,false,4226141955)},[_c('div',{staticClass:"flex flex-wrap-reverse items-center flex-grow justify-between",attrs:{"slot":"header"},slot:"header"},[_c('vs-button',{staticClass:"mr-4",attrs:{"type":"border","color":"warning","icon-pack":"feather"},on:{"click":_vm.Back}},[_vm._v(" "+_vm._s(_vm.$t("Back"))+" ")]),_c('div',{staticClass:"flex flex-wrap-reverse items-center"})],1),_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"FullName"}},[_vm._v(_vm._s(_vm.$t("FullName")))]),_c('vs-th',{attrs:{"sort-key":"PhoneNumber"}},[_vm._v(_vm._s(_vm.$t("PhoneNumber")))]),_c('vs-th',{attrs:{"sort-key":"Email"}},[_vm._v(_vm._s(_vm.$t("Email")))]),_c('vs-th',{attrs:{"sort-key":"Status"}},[_vm._v(" "+_vm._s(_vm.$t("Status"))+" ")]),_c('vs-th',{attrs:{"sort-key":"Status"}},[_vm._v(" "+_vm._s(_vm.$t("AccountType"))+" ")]),_c('vs-th',[_vm._v(_vm._s(_vm.$t("ClientProfile")))]),_c('vs-th',[_vm._v(_vm._s(_vm.$t("RequestBiadjo")))])],1)],2)],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }